import { of } from 'rxjs/index';
import { concatMap, catchError, mergeMap, filter, map } from 'rxjs/operators';
import { Epic, ofType } from 'redux-observable';
import { Observable } from 'rxjs/internal/Observable';
import { AppState, EpicDependencies } from 'store';
import { PRODUCT, LOCATION } from 'utils/domain/constants';
import { AppEpic } from 'epics';
import { AnyAction } from '@reduxjs/toolkit';
import {
  setViewConfig,
  setViewConfigFailure,
  appReady,
  setActiveViews,
  isLocationChange,
  matchViewConfigPath
} from './ViewConfig.slice';
import Scope, { AvailableMembers, Workflow } from 'services/Scope.client';
import { FacetEntry } from './ViewConfig.types';
import { get, isEmpty } from 'lodash';
import { LOCATION_CHANGE, LocationChangeAction, replace } from 'connected-react-router';
import { receivedScope, requestCreateScope } from 'state/scope/Scope.slice';

//  this watches for when the app first mounts and requests the view config
export const setupViewConfig: Epic<any, any, AppState, EpicDependencies> =
  (action$, state$, deps): Observable<AnyAction> => {
    const config = deps.serviceEnv.config;
    return action$.pipe(
      ofType(appReady.type), // fires when MainContainer mounts, so when the app first loads
      mergeMap(async (_incoming) => {
        const viewConfig = await config.getViewConfig();
        const currentPerspective = state$.value.viewConfigSlice.currentPerspective ||
          matchViewConfigPath(state$.value.router.location.pathname)?.params.perspective ||
          null;

        const maybeWorkflow = get(state$.value, 'scope.mainConfig.workflow') as Workflow | undefined;
        // if we can't find a workflow, default to pre-season
        const workflow = maybeWorkflow ? maybeWorkflow : 'pre-season';
        return setViewConfig(viewConfig, currentPerspective, workflow);
      }),
      catchError((err) => {
        // TODO: figure out the real error types here
        const stack = err.stack && typeof err.stack === 'string' ? err.stack : undefined;
        deps.serviceEnv.logging.error('An error occured fetching the view config', stack);
        return of(setViewConfigFailure(err));
      })
    );
  };

// this fetches and applies authorization information to the view config
// this is here because the view config is a static file and doesn't include
// the current user's authorizations,
// so we have to fetch each module and see if the members are empty
export const setupAuthViewConfigEpic: AppEpic =
  (action$, state$, deps): Observable<AnyAction> => {
    const client = deps.serviceEnv.axios;
    return action$.pipe(
      filter<ReturnType<typeof setViewConfig>>(action => setViewConfig.match(action)),
      filter((action) => action.payload.viewConfig.facets.some(f => f.disabled === undefined)),
      concatMap(async (action) => {
        // check facet endpoints for members to determine a user's access level
        const currentPerspective = state$.value.viewConfigSlice.currentPerspective ||
          matchViewConfigPath(state$.value.router.location.pathname)?.params.perspective || null;

        const maybeWorkflow = get(state$.value, 'scope.mainConfig.workflow') as Workflow | undefined;
        // if we can't find a workflow, default to pre-season
        const workflow = maybeWorkflow ? maybeWorkflow : 'pre-season';
        const incomingViewConfigs = action.payload.viewConfig;
        const perspPromises: Promise<void | AvailableMembers>[] = [];
        const authenticatedFacets: FacetEntry[] = [];
        const checkScope = new Scope(client);

        // once the facets are loaded, check for members in the default dims
        // if there are no members, then the user doesn't have access
        // so set the disabled flag
        incomingViewConfigs.facets.forEach((fa) => {
          // need to have something in prod and loc to count a perspecitve as valid
          perspPromises.push(checkScope.getAvailableMembers(fa.pathSlot).then(mems => {
            const hasValidMembers = [PRODUCT, LOCATION].every(dim => {
              return !isEmpty(mems.space[dim]);
            });
            authenticatedFacets.push({
              ...fa,
              disabled: !hasValidMembers
            });
          }));
        });
        return await Promise.all(perspPromises).then(() => {
          const authedViewConfig = {
            ...incomingViewConfigs,
            facets: authenticatedFacets
          };
          return setViewConfig(authedViewConfig, currentPerspective, workflow);
        });
      }),
      catchError((err: string) => of(setViewConfigFailure(err)))
    );
  };
export const completeUnfinishedRoute: Epic<any, any, AppState, EpicDependencies> =
  (action$, state$): Observable<AnyAction> => {
    return action$.pipe(
      // This attempts to handle an incomplete route
      ofType<LocationChangeAction>(LOCATION_CHANGE),
      map((action) => {
        const currentState = state$.value;
        const activeViews = currentState.viewConfigSlice.activeViews;
        const maybeWorkflow = get(state$.value, 'scope.mainConfig.workflow') as Workflow | undefined;
        // if we can't find a workflow, default to pre-season
        const workflow = maybeWorkflow ? maybeWorkflow : 'pre-season';

        const match = matchViewConfigPath(action.payload.location.pathname)?.params;
        if (match?.perspective && match.tab && !match.view && activeViews && workflow) {
          // if we have everything we need except a view, find the first bottom view and route to it
          // this doesn't handle routing if the view id is just incorrect for some reason
          const bottom = activeViews.find((v) => v.id === match.tab)!.views![0].views![0];
          if (bottom) {
            return {
              perspective: match?.perspective!,
              tab: match?.tab,
              view: bottom.id
            };
          }
        }
        return undefined;
      }),
      filter((maybeMatch) => inputIsNotNullOrUndefined(maybeMatch)),
      mergeMap((match) => {
        return of(replace(`${match!.perspective!}/${match!.tab!}/${match!.view}`, state$.value.router.location));
      }),
      catchError((err: string) => of(setViewConfigFailure(err)))
    );
  };

export function inputIsNotNullOrUndefined<T>(input: null | undefined | T): input is T {
  return input !== null && input !== undefined;
}
export const setupActiveViews: Epic<any, any, AppState, EpicDependencies> =
  (action$, state$): Observable<AnyAction> => {
    return action$.pipe(
      // when new scope is requested or location is changed, set the active views
      ofType<
        (ReturnType<typeof receivedScope> | LocationChangeAction | typeof setViewConfig)
      >(receivedScope.type, LOCATION_CHANGE, setViewConfig.type),
      map((action) => {
        if (requestCreateScope.match(action) && action.payload.initParams.type === 'with-smart-plan') return;
        const currentState = state$.value;
        const maybeCurrentFacet = setViewConfig.match(action) ?
          action.payload.facet : currentState.viewConfigSlice.facet;

        const maybeWorkflow = get(state$.value, 'scope.mainConfig.workflow') as Workflow | undefined;
        // if we can't find a workflow, default to pre-season
        const workflow = maybeWorkflow ? maybeWorkflow : 'pre-season';

        // combinging id's like this is not ideal
        const maybeViewId = maybeCurrentFacet && workflow ?
          `${maybeCurrentFacet.id}-${workflow}` :
          undefined;

        return maybeViewId;
      }),
      filter(inputIsNotNullOrUndefined),
      mergeMap((viewId) => of(setActiveViews(viewId))),
      catchError((err: string) => of(setViewConfigFailure(err)))
    );
  };
