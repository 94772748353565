import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import type { PlanId } from 'state/scope/codecs/PlanMetadata';
import { getScopeReadyData } from 'state/scope/Scope.types';
import type { Comment, ProtoComment } from './Comments.types';
import { ThunkApi } from 'store';

export const addComment = createAsyncThunk<
void,
ProtoComment,
ThunkApi & { rejectValue: string }>(
  'comments/addComment',
  async (newComment, { getState, rejectWithValue, extra }) => {
    const commentsService = extra.dependencies.serviceEnv.comments;

    const readyScope = getScopeReadyData(getState().scope);
    if (readyScope) {
      try {
        return await commentsService.addComment(newComment.planId, newComment);
        // new comments are fetched by Comments.listener
      }
      catch (err) {
        return rejectWithValue((err as Error).message);
      }
    }
    throw new Error('Scope needs to be ready to get comments');
  }
);

export const loadScopeComments = createAsyncThunk<
Comment[],
undefined,
ThunkApi & { rejectValue: string }>(
  'comments/loadScopeComments',
  async (_payload, { getState, rejectWithValue, extra }) => {
    const commentsService = extra.dependencies.serviceEnv.comments;
    const { scope, viewConfigSlice: { currentPerspective } } = getState();

    const maybeScopeData = getScopeReadyData(scope);
    if (maybeScopeData) {
      try {
        const planIds = [
          ...maybeScopeData.mainConfig.initializedPlans,
          ...maybeScopeData.mainConfig.uninitializedPlans
        ].map((p) => p.id);

        const commentsPromises = planIds.map((id) => {
          return commentsService.getPlanComments(id);
        });
        const commentsWithData = await Promise.all(commentsPromises);
        return commentsWithData.flatMap(c => c);
      }
      catch (err) {
        return rejectWithValue((err as Error).message);
      }
    }
    throw new Error('Scope needs to be ready to get comments');
  }
);
type CommentsWithPlanId = [PlanId, Comment[]]
export const loadPlanComments = createAsyncThunk<
CommentsWithPlanId,
PlanId,
ThunkApi & { rejectValue: string }>(
  'comments/loadPlanComments',
  async (commentsPlanId, { getState, rejectWithValue, extra }) => {
    const commentsService = extra.dependencies.serviceEnv.comments;
    const { scope, viewConfigSlice: { currentPerspective } } = getState();

    try {
      const commentsPromises = [commentsPlanId].map((id) => {
        return commentsService.getPlanComments(id);
      });
      const commentsWithData = await (await Promise.all(commentsPromises)).flatMap(c => c);
      return [commentsPlanId, commentsWithData];
    }
    catch (err) {
      return rejectWithValue((err as Error).message);
    }
  }
);

export const updateComment = createAsyncThunk<
AxiosResponse,
Comment,
ThunkApi & { rejectValue: string }>(
  'comments/updateComment',
  async (commentToUpdate, { getState, rejectWithValue, extra }) => {
    const commentsService = extra.dependencies.serviceEnv.comments;

    const readyScope = getScopeReadyData(getState().scope);
    if (readyScope) {
      try {
        return await commentsService.updateComment(commentToUpdate.commentId);
      }
      catch (err) {
        return rejectWithValue((err as Error).message);
      }
    }
    throw new Error('Scope needs to be ready to get comments');
  }
);

export const deleteComment = createAsyncThunk<
void,
string,
ThunkApi & { rejectValue: string }
>(
  'comments/deleteComment',
  async (commentIdToDelete, { getState, extra, rejectWithValue }) => {
    const commentsService = extra.dependencies.serviceEnv.comments;

    const readyScope = getScopeReadyData(getState().scope);
    if (readyScope) {
      try {
        return await commentsService.deleteComment(commentIdToDelete);
      }
      catch (err) {
        return rejectWithValue((err as Error).message);
      }
    }
    return rejectWithValue('Scope isnt ready, cant delete comments');
  }
);
