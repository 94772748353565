// // tslint:disable:object-shorthand-properties-first
import { AxiosInstance, AxiosPromise } from 'axios';
import qs from 'query-string';
import { Dispatch } from 'redux';
import browserHistory from '../../browserHistory';
import Scope, {
  ScopeCreateRequest
} from '../../services/Scope.client';
import { GridAsyncState, isScopeReady, isScopeNotReady, getScopeReadyData } from './Scope.types';
import { PerspectivePaths, SCOPETYPE_PLAN, SCOPETYPE_ACTUALS } from 'utils/domain/constants';
import { AppState, AppDispatch, AppThunkDispatch, ThunkApi } from 'store';
import {
  receivedCreateScope,
  scopeNotFound,
  requestScope,
  receivedScope,
  requestCreateScope,
  clearScope,
  requestSeedCurrentScope,
  receivedSeedCurrentScope,
  requestImportVersion,
  updateGridAsyncState,
  requestScopeLockState,
  receivedScopeLockState,
  requestSetEop,
  requestUndoScope,
  receiveUndoScope,
  requestRedoScope,
  receiveRedoScope,
  requestRefreshGrid,
  receiveRefreshGrid,
  receivedSetEop
} from './Scope.slice';

import {
  requestAvailableScopes,
  receivedAvailableScopes
} from 'state/ViewConfig/ViewConfig.slice';

import { push } from 'connected-react-router';
import { matchViewConfigPath, MFP_PATH_ROUTE } from 'state/ViewConfig/ViewConfig.slice';
import { generatePath } from 'react-router';
import { PlanId } from './codecs/PlanMetadata';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { SeedActuals, SeedPlan } from './ScopeManagement.slice';
import { Workflows } from './codecs/Workflows';
import { isRight } from 'fp-ts/es6/Either';

function maybeUpdateScopeHistory(scopeId: string | undefined) {
  const search = qs.parse(browserHistory.location.search);
  if (search.scope !== scopeId) {
    const newHistoryState = { ...browserHistory.location };
    // @ts-ignore
    search.scope = scopeId;
    newHistoryState.search = qs.stringify(search);
    browserHistory.push(newHistoryState);
  }
}

// TODO: I don't think this is going to work
export function setScope(scopeId: string) {
  maybeUpdateScopeHistory(scopeId);
}

export function getScope(
  scopeId: string,
  destinationPerspective: PerspectivePaths | false = false
) {
  return (dispatch: Dispatch<any>, getState: () => AppState, extra: ThunkApi['extra']) => {
    const axios = extra.dependencies.serviceEnv.axios;
    const maybeCurrentPerspective = getState().viewConfigSlice.facet?.pathSlot;
    const switchPerspective = typeof destinationPerspective === 'string' &&
      typeof maybeCurrentPerspective === 'string' &&
      destinationPerspective !== maybeCurrentPerspective;
    if (!scopeId) {
      const result = dispatch(scopeNotFound({ scopeId, error: 'Scope Not Selected' }));
      maybeUpdateScopeHistory(scopeId);
      return Promise.resolve(result);
    }
    if (switchPerspective) {
      // fast scope switching (from the ScopeStatusPopover) can allow user's to quickly switch between
      // different perspectives
      // a lot of garbage code relies on knowing the current perspective from window.location, so we have to detect that
      // scenario and change path here
      const maybeMatchedPath = matchViewConfigPath(getState().router.location.pathname);
      if (maybeMatchedPath && typeof destinationPerspective === 'string') {
        const newLocation = { ...maybeMatchedPath.params, perspective: destinationPerspective };
        dispatch(push(generatePath(MFP_PATH_ROUTE, newLocation)));
      }
    }
    dispatch(requestScope({ scopeId }));

    return new Scope(axios)
      .getScope(scopeId)
      .then(serverScope => {
        if (serverScope.scopeReady) {
          // @ts-ignore
          const res = dispatch(receivedScope({ ...serverScope }, axios));
          dispatch(getScopeLockState(axios, scopeId));
          maybeUpdateScopeHistory(scopeId);
          if (!serverScope.initialized) {
            // getEop and getSeed were here, need to ensure they act the same in scope.listener
          }
          return res;
        } else if (!serverScope.scopeReady) {
          const res = dispatch(receivedScope({ ...serverScope }, axios));
          dispatch(getScope(scopeId));
          maybeUpdateScopeHistory(scopeId);
          return res;
        }
      })
      .catch(error => {
        const res = dispatch(scopeNotFound({ scopeId, error }));
        maybeUpdateScopeHistory(undefined);
        return res;
      });
  };
}

export function newScope(axios: AxiosInstance, create: ScopeCreateRequest) {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const viewState = getState().viewConfigSlice;
    dispatch(requestCreateScope(create));
    if (!viewState.currentPerspective) { throw new Error('Can\'t make a scope without a known module'); }
    const currentPersp = viewState.currentPerspective;
    return new Scope(axios).createScope(create, currentPersp).then(scopeResp => {
      dispatch(receivedCreateScope(scopeResp));
      maybeUpdateScopeHistory(scopeResp.id);

      if (isScopeNotReady(scopeResp)) {
        dispatch(getScope(scopeResp.id));
      }
      if (isScopeReady(scopeResp) && !scopeResp.initialized) {
        // getEop and getSeed were here, need to ensure they act the same in scope.listener
      }
    });
  };
}

export const getAvailableScopeMembers = (axios: AxiosInstance) => {
  return async (dispatch: AppThunkDispatch, getState: () => AppState) => {
    const viewState = getState().viewConfigSlice;
    dispatch(requestAvailableScopes());
    if (!viewState.currentPerspective) { return; }
    const currentPersp = viewState.currentPerspective;
    const data = await new Scope(axios)
      .getAvailableMembers(currentPersp);
    return dispatch(receivedAvailableScopes(data));
  };
};

export function resetScope() {
  return (dispatch: AppDispatch) => {
    return dispatch(clearScope());
  };
}

export const seedAvailableScope = createAsyncThunk<
void,
SeedPlan | SeedActuals,
ThunkApi & { rejectValue: string }>(
  'scopeManagement/seedScope',
  async (seed, { getState, rejectWithValue, dispatch, extra }) => {
    dispatch(requestSeedCurrentScope());
    const scopeReadyData = getScopeReadyData(getState().scope);
    if (scopeReadyData && seed.seedTime !== null) {
      try {
        return await new Scope(extra.dependencies.serviceEnv.axios)
          .seedScope(scopeReadyData.mainConfig.id, seed)
          .then(() => dispatch(receivedSeedCurrentScope()))
          .then(() => dispatch(forceRefreshGrid()));
        // new comments are fetched by Comments.listener
      }
      catch (err) {
        return rejectWithValue(('No scope or target found when seeding'));
      }
    }
    throw new Error('Scope needs to be ready to get comments');
  }
);

export function importVersion(seedId: number, applyTo: PlanId) {
  return (dispatch: Dispatch<any>, getState: () => AppState, extra: ThunkApi['extra']) => {
    const scope = getState().scope;

    const scopeId = getScopeReadyData(scope)?.mainConfig.id;
    if (!scopeId) { throw new Error('Import was called without a scopeid, which shouldn\'t happen'); }
    dispatch(requestImportVersion());
    return new Scope(extra.dependencies.serviceEnv.axios)
      .importVersion(scopeId, seedId, applyTo)
      .then(() => dispatch(receivedSeedCurrentScope()))
      .then(() => dispatch(forceRefreshGrid()));
  };
}

export function overlayVersion(overlayId: string, applyTo: PlanId) {
  return (dispatch: Dispatch<any>, getState: () => AppState, extra: ThunkApi['extra']) => {
    const scope = getState().scope;

    const scopeId = getScopeReadyData(scope)?.mainConfig.id;
    if (!scopeId) { throw new Error('Import was called without a scopeid, which shouldn\'t happen'); }
    dispatch(requestImportVersion());
    return new Scope(extra.dependencies.serviceEnv.axios)
      .postOverlay(scopeId, applyTo, overlayId)
      .then(() => dispatch(receivedSeedCurrentScope()))
      .then(() => dispatch(forceRefreshGrid()));
  };
}

export function setGridAsyncState(newState: GridAsyncState) {
  return (dispatch: Dispatch<any>) => {
    dispatch(updateGridAsyncState(newState));
  };
}

export function getScopeLockState(axios: AxiosInstance, scopeId: string) {
  return (dispatch: Dispatch<any>) => {
    dispatch(requestScopeLockState());
    return new Scope(axios)
      .getScopeLockState(scopeId)
      .then(resp => dispatch(receivedScopeLockState(resp)));
  };
}

export function unlockScopeLockState(axios: AxiosInstance, scopeId: string) {
  return (dispatch: Dispatch<any>) => {
    return new Scope(axios)
      .unlockScopeLockState(scopeId)
      .then(data => dispatch(receivedScopeLockState(data)));
  };
}

export function saveVersion(
  axios: AxiosInstance,
  scopeId: string,
  versionName?: string | null
) {
  return (dispatch: Dispatch<any>) => {
    if (!versionName) {
      versionName = null;
    }
    return new Scope(axios).saveVersion(scopeId, versionName);
  };
}

export function optimisticSetLockState(scopeId: string, scopeState: boolean) {
  return (dispatch: Dispatch<any>) => {
    return dispatch(receivedScopeLockState(scopeState));
  };
}

export function setEop(
  applyTo: number,
  eopIdOrYear: number | string
) {
  return (dispatch: AppDispatch, getState: () => AppState, extra: ThunkApi['extra']) => {
    dispatch(requestSetEop());
    const applyToPlanId = PlanId.decode(applyTo);
    const eopPlanIdOrYearString = typeof eopIdOrYear === 'number' ? PlanId.decode(eopIdOrYear) : eopIdOrYear;
    const readyScope = getScopeReadyData(getState().scope);

    if (!readyScope || !isRight(applyToPlanId)) { throw new Error('Scope wasn\'t ready to balance'); }
    if (typeof eopPlanIdOrYearString === 'string' || isRight(eopPlanIdOrYearString)) {
      const validatedEopPlanIdOrYearString =
        typeof eopPlanIdOrYearString !== 'string' && isRight(eopPlanIdOrYearString) ?
          eopPlanIdOrYearString.right :
          eopPlanIdOrYearString;
      const scopeId = readyScope.mainConfig.id;
      return new Scope(extra.dependencies.serviceEnv.axios)
        .setEop(
          scopeId,
          applyToPlanId.right,
          validatedEopPlanIdOrYearString,
          typeof validatedEopPlanIdOrYearString === 'string' ? SCOPETYPE_ACTUALS : SCOPETYPE_PLAN
        )
        .then(_data => dispatch(receivedSetEop()));
    }
    throw new Error('An error occured copying EOP to BOP');
  };
}

export function undoScope(axios: AxiosInstance, scopeId: string) {
  return (dispatch: Dispatch<any>) => {
    dispatch(requestUndoScope());
    return new Scope(axios)
      .undoScope(scopeId)
      .then(() => dispatch(receiveUndoScope()))
      .then(() => dispatch(forceRefreshGrid()));
  };
}

export function redoScope(axios: AxiosInstance, scopeId: string) {
  return (dispatch: Dispatch<any>) => {
    dispatch(requestRedoScope());
    return new Scope(axios)
      .redoScope(scopeId)
      .then(() => dispatch(receiveRedoScope()))
      .then(() => dispatch(forceRefreshGrid()));
  };
}

export function forceRefreshGrid() {
  return (dispatch: Dispatch<any>) => {
    dispatch(requestRefreshGrid());
  };
}

export function completeRefreshGrid() {
  return (dispatch: Dispatch<any>) => {
    dispatch(receiveRefreshGrid());
  };
}

export const fetchWorkflows = createAsyncThunk<
Record<number, Workflows>,
undefined,
ThunkApi & { rejectValue: string }>(
  'scope/fetchWorkflows',
  async (_payload, { getState, rejectWithValue, extra }) => {

    const readyScope = getScopeReadyData(getState().scope);
    if (readyScope) {
      try {
        const planIds = [
          ...readyScope.mainConfig.initializedPlans,
          ...readyScope.mainConfig.uninitializedPlans
        ].map((p) => p.id);

        const planWorkflowPromises = planIds.map((id) => {
          return new Scope(extra.dependencies.serviceEnv.axios).getWorkflows(readyScope.mainConfig.id, id);
        });
        const planWorkflowData = await Promise.all(planWorkflowPromises);

        // SPIKE: is the order of these deterministic?
        const plansMap = new Map(planIds.map((pId, idx) => [pId, planWorkflowData[idx]]));
        return Object.fromEntries(plansMap) as Record<number, Workflows>;
      }
      catch (err) {
        return rejectWithValue((err as Error).message);
      }
    }
    throw new Error('Scope needs to be ready to get comments');
  }
);

export const requestAddPrivateVersion = createAsyncThunk<
void,
{ versionName: string, applyTo: PlanId, smartSave: boolean },
ThunkApi & { rejectValue: string }>(
  'scope/requestAddPrivateVersion',
  async (payload, { getState, rejectWithValue, extra }) => {
    // we take in the requested private version, and on success, re-request workflows
    const client = extra.dependencies.serviceEnv.axios;
    const nameToAdd = payload.versionName;
    const readyScope = getScopeReadyData(getState().scope);

    try {
      if (readyScope) {
        // eslint-disable-next-line max-len
        const savePromise = await new Scope(client)[payload.smartSave ? 'saveSmartPlanVersion' : 'saveVersion'](readyScope.mainConfig.id, nameToAdd);
        return savePromise;
      }
    }
    catch (err) {
      return rejectWithValue((err as Error).message);
    }
    throw new Error('Scope needs to be ready in order to save a version');
  });
